import { useIsDesktop } from "common/MediaQueries"
import CdnImage from "common/utils/CdnImage"
import { getImageCDNUrl } from "common/utils/imageMap"
import QPlayer from "components/custom/QPlayer"
import { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import { HomePageTitle } from "../components/HomePageTitle"
import { HomeProps } from "../components/HomeProps"

const StyledContainer = styled.div`
  padding: 0px;
  position: relative;
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0px;
    margin-bottom: 15px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 69px;
    height: 742px;
    max-height: calc(100vh - 61px);
  }
`
const StyledBanner = styled.div<{ $isVideoData: boolean }>`
  flex-grow: 1;
  height: 670px;
  max-height: calc(100vh - 61px);
  object-fit: cover;
  width: 100%;
  position: relative;

  ${(p) => p.theme.breakpoints.up("md")} {
    height: 742px;
    position: inherit;
    width: 865px;
    left: 0;
  }

  /* ${(p) => p.theme.breakpoints.down("md")} {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.63)
      );
    }
  } */
`

const StylesHorizontalBorder = styled.div`
  position: absolute;
  top: 330px;
  left: 0;
  right: -1000px;
  height: 1px;
  background-color: ${colors.activitiesBorder};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    top: 550px;
    z-index: 20;
  }
`
const StylesVerticalBorder = styled.div`
  height: 100%;
  width: 1px;
  position: fixed;
  /* margin-right: 100px; */

  background-color: ${colors.activitiesBorder};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-right: 50px;
    position: absolute;
    z-index: 20;
    height: 100%;
  }
`

export const FirstBlock = ({ data, fallbackTitle }: HomeProps) => {
  const isDesktopScreen = useIsDesktop()
  const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    const headerElement = document?.querySelector("header")
    if (!headerElement) {
      return
    }
    // TODO сбросить стили есть меняется route
    if (!isDesktopScreen) {
      handleSetMobileStyle(headerElement)
    } else {
      handleResetMobileStyle(headerElement)
    }
  }, [isDesktopScreen])

  const handleResetMobileStyle = (header: HTMLElement) => {
    header.style.border = ""
  }

  const handleSetMobileStyle = (header: HTMLElement) => {
    header.style.border = colors.none
  }
  data.mediaGalleryDesktop
  const image = isDesktopScreen
    ? data?.mediaGalleryDesktop?.[0]
    : data?.mediaGalleryMobile?.[0]
  const isVideo =
    (image?.media?.type as unknown as string) === "Video" ? true : false
  const poster = isDesktopScreen
    ? data?.poster?.posterDesktop?.media?.publicUrl
    : data?.poster?.posterMobile?.media?.publicUrl
  return (
    <StyledContainer>
      <StylesVerticalBorder />
      <StylesHorizontalBorder />

      <StyledBanner $isVideoData={isVideo}>
        {isVideo ? (
          <>
            <QPlayer
              videoUrl={getImageCDNUrl(image?.media?.publicUrl)}
              altText={image?.media?.altText || "אמות"}
              onReady={() => setVideoLoaded(true)}
              videoName={image?.media?.title || "אמות"}
              description={image?.media?.description || "אמות"}
              uploadDate={image?.media?.created}
              thumbnailUrl={poster || ""}
            />
            {!videoLoaded && poster && (
              <CdnImage
                layout="fill"
                priority
                src={poster || ""}
                alt={image?.media?.altText || "אמות"}
                objectFit="cover"
              />
            )}
          </>
        ) : (
          <CdnImage
            layout="fill"
            priority
            src={image?.media?.publicUrl || "/"}
            alt={image?.media?.altText}
            objectFit="cover"
          />
        )}

        <HomePageTitle
          titleText={isVideo ? "" : data?.main?.titleText?.value}
          fallbackTitle={fallbackTitle}
        />
      </StyledBanner>
    </StyledContainer>
  )
}
