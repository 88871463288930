import { BoxProps } from "@material-ui/core"
import { AssetListType } from "common/AssetType"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useMemo } from "react"
import styled from "styled-components"
import { CustomTextField, CustomTextFieldProps } from "./CustomTextField"

const StyledCustomTextField = styled(CustomTextField)`
  display: inline-flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 297px;

  input {
    padding: 0 !important;
  }

  fieldset {
    border: none;
  }

  .MuiSvgIcon-root {
    display: none;
  }
  .MuiFormLabel-root {
    color: #5b626a;
    font-size: 14px;
  }

  .MuiInputLabel-shrink {
    visibility: hidden;
  }
`
type CenteredTextFieldProps = CustomTextFieldProps & {
  forceReset?: boolean
  boxProps?: BoxProps
  onSearch: (list: AssetListType[]) => void
  onCancel?: () => void
  onEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  alwaysShowCancel?: boolean
  hideMagnifier?: boolean
  className?: string
  extraLabel?: string
}
export const FormAssetsSearch: React.FC<CenteredTextFieldProps> = ({
  forceReset,
  onSearch,
  onCancel,
  onEnter,
  alwaysShowCancel,
  hideMagnifier,
  className,
  extraLabel,
  ...props
}) => {
  const {
    setIsChanging,
    setOpenMapOrList,
    setPopUpIsOpen,
    fetchAssets,
    value,
    setValue,
    activeCategoryType,
  } = useAssetSearchContext()

  const reloadValues = useMemo(() => {
    return debounce((value: string) => {
      setIsChanging(true)
      fetchAssets(value)
        .then(onSearch)
        .finally(() => {
          setIsChanging(false)
        })
    }, 1000)
  }, [activeCategoryType])

  useEffect(() => {
    if (forceReset) setValue("")
  }, [forceReset])

  const closeMenuAndOpen = (buttonLabel: "list" | "map") => {
    setOpenMapOrList(buttonLabel)
    setPopUpIsOpen(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onEnter?.(event)
      closeMenuAndOpen("list")
    }
  }

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value)
      reloadValues(event.target.value)
    },
    [reloadValues]
  )

  const cancelHandler = useCallback(() => {
    setValue("")
    reloadValues("")
    onCancel?.()
  }, [onCancel])

  return (
    <StyledCustomTextField
      {...props}
      className={className}
      onChange={changeHandler}
      onCancel={cancelHandler}
      alwaysShowCancel={alwaysShowCancel}
      hideMagnifier={hideMagnifier}
      InputLabelProps={{ disableAnimation: true }}
      id="assetSearchField"
      inputProps={{
        "aria-label": `${extraLabel} ${props.label as string}`,
      }}
      InputProps={{
        value,
        onKeyDown: handleKeyDown,
        type: "search",
      }}
    />
  )
}
