import { useRouter } from "next/router"

const useLocationLocale = () => {
  const { locale } = useRouter()

  const mapLocation = (location: string) => {
    if (!location) return location
    const [en, he] = location.split("__")
    const localeLocation = locale === "he" ? he : en
    return localeLocation?.trim().replace("_", " ") || location
  }

  return mapLocation
}

export default useLocationLocale
