import { Container, Typography } from "@material-ui/core"
import { Category } from "api"
import { getAssetTypeByString, getCategoryImageSource } from "common/AssetType"
import { Desktop, Mobile } from "common/MediaQueries"
import { getImageCDNUrl } from "common/utils/imageMap"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useLocale, useTranslations } from "next-intl"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import styled from "styled-components"
import colors from "theme/colors"
import { ActivityContent } from "../components/ActivityContent"
import { GallerySlider } from "../components/GallerySlider"
import { HomeProps } from "../components/HomeProps"
import { TwoRedCircles } from "../components/TwoRedCircles"

const StyledContainerWrapper = styled(Container)<{ $inView: boolean }>`
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    margin: 0;
    padding: 0;
  }
  transition: all 0.3s ease-in-out;
  opacity: ${(p) => (p.$inView ? 1 : 0)};
  transform: ${(p) => (p.$inView ? "translateY(0)" : "translateY(100px)")};
`
const StyledContainer = styled.div<{ $rtl: boolean }>`
  display: grid;
  grid-template-columns: 4fr 6fr;
  margin-bottom: 150px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
`

const StyledActiviesLeftItem = styled.div`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 23px;

    z-index: 20;
    background-color: ${colors.white};
  }
`

const StyledRightItemWrapper = styled.div`
  min-width: 100%;
`

const StyledActiviesRightItem = styled.div`
  z-index: 20;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    background-color: ${colors.white};
  }
`

const StyledActiviesTitleDesktop = styled(Typography).attrs({ variant: "h2" })<{
  $rtl: boolean
}>`
  position: relative;
  display: flex;

  justify-content: flex-start;
  height: 84px;
  margin-bottom: 64px;
  text-align: center;
  font-size: 70px;
  font-weight: 400;
  ${(p) => p.theme.breakpoints.up("md")} {
    top: -25px;
    padding-right: ${(p) => (p.$rtl ? "88px" : 0)};
    padding-left: ${(p) => (p.$rtl ? 0 : "88px")};
  }
`

const StyledActiviesTitleMobile = styled.div`
  padding-left: 30px;
  padding-right: 65px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.black};
`

const StyledButtonContainer = styled(TabList)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding-inline-start: 58px;
  padding-inline-end: 36px;
  list-style: none;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 4px 0 1px;
    justify-content: space-around;
    max-width: 550px;
    padding: 0px 26px 0;
  }
  @media (max-width: 375px) {
    padding: 0px 10px 0;
  }

  .MuiButton-root {
    min-width: 0;
  }
`

const TwoRedCirclesContainer = styled.div<{ $rtl: boolean }>`
  padding-left: ${(p) => (p.$rtl ? "15px" : 0)};
  padding-right: ${(p) => (p.$rtl ? 0 : "15px")};
  padding-top: 15px;
  display: flex;
  align-items: center;
`
const StyledCategory = styled(Tab)`
  margin-bottom: 25px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: transparent;
    h4 {
      color: ${(p) => p.theme.colors.black};
      font-weight: 500;
    }
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    max-width: 57px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 114px;
  }
`
const StyledCategoryIcon = styled.img`
  width: 54px;
  height: 54px;
  margin-bottom: 12px;
  ${(p) => p.theme.breakpoints.down("xs")} {
    width: 45px;
    height: 45px;
    margin-bottom: 8px;
  }
`

const StyledCategoryTitle = styled(Typography).attrs({
  component: "h4",
})<{ $isSelected: boolean }>`
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: ${(p) => (p.$isSelected ? p.theme.colors.black : p.theme.colors.gray)};
  font-weight: ${(p) => (p.$isSelected ? "500" : "normal")};
  height: 33.594px;
  text-transform: none;
  ${(p) => p.theme.breakpoints.down("xs")} {
    font-size: 12px;
  }
`
export const AssetsBlock = ({ data, objectForSwiper }: HomeProps) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    rootMargin: "300px 0px 0px 0px",
  })
  const ref = useRef<HTMLImageElement[]>([])
  // data.categories
  const t = useTranslations("homepage")
  const locale = useLocale()
  const { push } = useRouter()

  const [ActiveCategory, setActiveCategory] = useState<Category>(
    data?.categories ? data?.categories[0] : null
  )

  const { activeCategoryType, setActiveCategoryType } = useAssetSearchContext()
  useEffect(() => {
    const type = getAssetTypeByString(
      data.categories[0].link?.page?.typeId?.toLowerCase() || ""
    )
    if (type !== activeCategoryType) {
      setActiveCategory(data?.categories[0])
      setActiveCategoryType(type)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <StyledContainerWrapper ref={inViewRef} $inView={inView}>
      <StyledContainer $rtl={locale === "he"}>
        <Mobile>
          <StyledActiviesTitleMobile>
            {data?.main?.shortDescription?.value}
          </StyledActiviesTitleMobile>
        </Mobile>

        <StyledRightItemWrapper>
          <StyledActiviesRightItem>
            <Typography variant="srOnly" component="h3">
              {t("assetsGallery")}
            </Typography>
            <GallerySlider
              type={activeCategoryType}
              objectForSwiper={objectForSwiper}
              ActiveCategory={ActiveCategory}
            />
          </StyledActiviesRightItem>
        </StyledRightItemWrapper>

        <StyledActiviesLeftItem>
          <Desktop>
            <StyledActiviesTitleDesktop $rtl={locale === "he"}>
              <TwoRedCirclesContainer $rtl={locale === "he"}>
                <TwoRedCircles />
              </TwoRedCirclesContainer>
              {t("amotAssets")}
            </StyledActiviesTitleDesktop>
          </Desktop>

          <Tabs
            direction={locale === "he" ? "rtl" : "ltr"}
            onSelect={(index) => {
              const type = getAssetTypeByString(
                data.categories[index].link?.page?.typeId?.toLowerCase() || ""
              )
              if (type !== activeCategoryType) {
                setActiveCategory(data?.categories[index])
                setActiveCategoryType(type)
              }
            }}
          >
            <StyledButtonContainer>
              {data?.categories?.map((category, index) => {
                const type = getAssetTypeByString(
                  category.link?.page?.typeId?.toLowerCase() || ""
                )
                return (
                  <StyledCategory
                    key={index}
                    onClick={() => {
                      if (type === "supermarket") {
                        push("/assets-list/supermarkets")
                      } else {
                        setActiveCategoryType(type)
                        setActiveCategory(category)
                      }
                    }}
                    onMouseOver={() => {
                      if (ref.current) {
                        ref.current[index].src = getCategoryImageSource(
                          type,
                          type
                        )
                      }
                    }}
                    onMouseLeave={() => {
                      if (ref.current) {
                        ref.current[index].src = getCategoryImageSource(
                          type,
                          activeCategoryType
                        )
                      }
                    }}
                    role={type === "supermarket" && "link"}
                  >
                    <StyledCategoryIcon
                      ref={(el) => (ref.current[index] = el)}
                      src={getImageCDNUrl(
                        getCategoryImageSource(type, activeCategoryType)
                      )}
                      alt=""
                      role="presentation"
                      aria-hidden="true"
                    />
                    <StyledCategoryTitle
                      $isSelected={type === activeCategoryType}
                      role="presentation"
                    >
                      {category?.title?.value}
                    </StyledCategoryTitle>
                  </StyledCategory>
                )
              })}
            </StyledButtonContainer>
            {data?.categories?.map((category, index) => {
              const type = getAssetTypeByString(
                category.link?.page?.typeId?.toLowerCase() || ""
              )
              return (
                <TabPanel
                  key={index}
                  tabIndex={type === activeCategoryType ? 0 : null}
                >
                  <ActivityContent category={category} categoryType={type} />
                </TabPanel>
              )
            })}
          </Tabs>
        </StyledActiviesLeftItem>
      </StyledContainer>
    </StyledContainerWrapper>
  )
}
