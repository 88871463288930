import { useRouter } from "next/router"
import styled from "styled-components"
import colors from "theme/colors"

const StyledWrapper = styled.div`
  position: relative;
`

const StyledFrame = styled.iframe`
  padding: 25px 10px 10px;
  border: none;
  border-radius: 12px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  width: 100%;
  height: 350px;
  ${({ theme }) => theme.breakpoints.down("xs")} {
    padding: 20px 16px;
    box-shadow: 0 15px 40px 10px rgba(0, 0, 0, 0.2);
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    box-sizing: content-box !important;
  }
`

const StyledBigRedCircle = styled.div<{ $isRtl: boolean }>`
  position: absolute;
  top: -115px;
  left: ${(props) => (props.$isRtl ? " -310px" : "auto")};
  right: ${(props) => (!props.$isRtl ? " -310px" : "auto")};

  width: 526px;
  height: 526px;
  justify-content: center;
  align-items: center;
  display: flex;

  border-radius: 526px;
  border: 100px solid ${colors.red};
  opacity: 0.4;
  z-index: -1;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    left: -190px;
    top: -145px;

    width: 320px;
    height: 320px;

    border: 60px solid ${colors.red};
  }
`

const StyledBigRedCircleInner = styled.div`
  width: 175px;
  height: 175px;

  border-radius: 175px;
  background-color: ${colors.red};

  ${({ theme }) => theme.breakpoints.down("xs")} {
    width: 106px;
    height: 106px;
  }
`

export const Graphic = () => {
  const { locale } = useRouter()

  return (
    <StyledWrapper>
      <StyledBigRedCircle $isRtl={locale === "he"}>
        <StyledBigRedCircleInner />
      </StyledBigRedCircle>
      <StyledFrame
        key={`https://chart.bursagraph.co.il/amot.shtml${
          locale === "he" ? "?lang=heb" : "?lang=eng"
        }`}
        src={`https://chart.bursagraph.co.il/amot.shtml${
          locale === "he" ? "?lang=heb" : "?lang=eng"
        }&sid=${Math.random()}`}
        loading="lazy"
      />
    </StyledWrapper>
  )
}

export default Graphic
