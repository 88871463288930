import { Box, Button, TextField, TextFieldProps } from "@material-ui/core"
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete"
import { Desktop } from "common/MediaQueries"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useTranslations } from "next-intl"
import Image from "next/image"
import React from "react"
import styled from "styled-components"
import colors from "theme/colors"

type Position = "center" | "right"

export type CustomTextFieldProps = TextFieldProps & {
  position?: Position
  shrink?: boolean
  autocompleteParams?: AutocompleteRenderInputParams
  onCancel?: () => void
  alwaysShowCancel?: boolean
  hideMagnifier?: boolean
}

const StyledBox = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  position: relative;
`

const StyledSearchIcon = styled.img`
  width: 15px;
  height: 15px;
  /* margin-top: 5px; */
  margin-left: 5px;

  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: 0;
    margin-left: 0;
  }
`
const StyledListButton = styled(Button)`
  border-radius: 6px;
  margin-right: auto;
  margin-left: 47px;
  padding: 10px;
  height: 33px;
  background-color: ${colors.alabaster};
  .Mui-Button-label {
    display: flex;
    align-items: center;
  }
  flex-shrink: 0;
`

const StyledCancel = styled.span`
  border: 1px solid ${colors.black};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
`
const StyledCancelIcon = styled.img`
  width: 8px;
  height: 8px;
`
const StyledCategoryWrapper = styled.span`
  height: 45px;
  width: 45px;
  /* opacity: 0.16; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const StyledArrow = styled(Button)`
  .MuiButton-root {
    border-radius: 8px;
  }
`
const StyledList = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray};
  margin-left: 5px;
  margin-top: 2px;
`
const StyledIconList = styled.span`` //modifiy to next

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  position = "center",
  InputProps,
  autocompleteParams,
  onCancel,
  alwaysShowCancel = false,
  hideMagnifier,
  ...props
}) => {
  const {
    setOpenCategoriesDrawer,
    activeCategoryType,
    openMapOrList,
    openCategoriesDrawer,
    setSelectedAssetsResult,
    setOpenMapOrList,
  } = useAssetSearchContext()
  const t = useTranslations("searchPopup")

  const cancelAsset = () => {
    setSelectedAssetsResult(null)
    onCancel?.()
  }
  return (
    <StyledBox>
      <Desktop>
        {openCategoriesDrawer && (
          <StyledArrow onClick={() => setOpenCategoriesDrawer(false)}>
            <Image width={14} height={7} src="/arrow-simple-down.svg" />
          </StyledArrow>
        )}
        {!openCategoriesDrawer &&
          (openMapOrList === "map" || openMapOrList === "list") && (
            <StyledCategoryWrapper
              onClick={() => setOpenCategoriesDrawer(true)}
            >
              <Image
                width={30}
                height={30}
                src={`/${activeCategoryType}-not-selected.png`}
              />
            </StyledCategoryWrapper>
          )}
      </Desktop>

      {!hideMagnifier && <StyledSearchIcon src="/search-icon.webp" />}

      <TextField
        data-position={position}
        autoComplete="off"
        InputProps={{
          ...autocompleteParams?.InputProps,
          ...InputProps,
        }}
        {...props}
        inputProps={{ ...autocompleteParams?.inputProps, ...props.inputProps }}
        id={props.id || autocompleteParams?.id}
      />
      {(InputProps?.value || alwaysShowCancel) && (
        <StyledCancel
          role="button"
          tabIndex={0}
          aria-label={t("clearText")}
          onClick={cancelAsset}
        >
          <StyledCancelIcon src="/closeMenu.svg" />
        </StyledCancel>
      )}
      <Desktop>
        {!!openMapOrList?.length && (
          <>
            {openMapOrList === "list" && (
              <StyledListButton onClick={() => setOpenMapOrList("map")}>
                <StyledList>{t("showOnMap")}</StyledList>
                <StyledIconList>
                  <Image width={18} height={18} src="/location-icon.svg" />
                </StyledIconList>
              </StyledListButton>
            )}
            {openMapOrList === "map" && (
              <StyledListButton onClick={() => setOpenMapOrList("list")}>
                <StyledList>{t("showList")}</StyledList>
                <Image width={18} height={18} src="/metro-list.svg" />
              </StyledListButton>
            )}
          </>
        )}
      </Desktop>
    </StyledBox>
  )
}
