import { Box, Typography } from "@material-ui/core"
import { useLocale } from "next-intl"
import styled from "styled-components"
import colors from "theme/colors"

const StyledPageTitleText = styled.span`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    line-height: 50px;
  }
`

const StyledPageTitleContainer = styled(Box).attrs({ component: "h1" })<{
  $isRtl: boolean
}>`
  font-family: "Century Gothic", "FbJabutinski", sans-serif;
  position: absolute;
  bottom: 1.5em;
  right: 0;
  font-size: 125px;
  width: 450px;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  line-height: 0.6;
  height: auto;
  z-index: 999;
  align-items: baseline;
  text-align: left;
  color: white;
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    left: 16px;
    font-size: 80px;
    bottom: 165px;
    right: auto;
    left: 15px;
    flex-direction: column;
    align-items: ${(props) => (props.$isRtl ? "flex-end" : "flex-start")};
    height: auto;
  }
`

const StyledYouWord = styled.span`
  color: ${colors.red};
  position: absolute;
  bottom: 0;
  left: calc(100% + 0.2em);
  direction: ltr;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: static;
    left: auto;
  }
`

//function that split title to two arrays, when the last one it the last word
const splitTitle = (title: string) => {
  if (!title) {
    return ["", ""]
  }
  const words = title?.split(" ")
  const lastWord = words[words?.length - 1]
  const titleWithoutLastWord = words.slice(0, words.length - 1).join(" ")
  return [titleWithoutLastWord, lastWord]
}

type Props = {
  titleText: string
  fallbackTitle?: string
}
export const HomePageTitle = ({ titleText, fallbackTitle }: Props) => {
  const locale = useLocale()
  const titleArray = splitTitle(titleText)
  if (!titleText) {
    return (
      <Typography variant="srOnly" component="h1">
        {fallbackTitle}
      </Typography>
    )
  }
  return (
    <StyledPageTitleContainer $isRtl={locale === "he"}>
      <StyledPageTitleText>{titleArray[0]}</StyledPageTitleText>
      <StyledYouWord>{titleArray[1]}</StyledYouWord>
    </StyledPageTitleContainer>
  )
}
