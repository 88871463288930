import { Typography } from "@material-ui/core"
import { BlogMain, PageField } from "api"
import { Desktop } from "common/MediaQueries"
import StickyImagePageLayout from "common/StickyImagePageLayout"
import QMediaComponent from "components/custom/QMediaComponent"
import { useLocale } from "next-intl"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { fontCondensed } from "theme/GlobalStyle"
import colors from "theme/colors"
import { NewsSlider } from "../components/NewsSlider"
import { TwoRedCircles } from "../components/TwoRedCircles"

//Styles

const StyledContainer = styled.div`
  margin-bottom: 50px;
`

const StyledStickyContainer = styled(StickyImagePageLayout)``

const StyledInnerContainer = styled.div<{
  $about: boolean
  $rtl: boolean
  $inView: boolean
}>`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    padding-inline: 16px;
  }
  transition: all 0.3s ease-in-out;
  opacity: ${(p) => (p.$inView ? 1 : 0)};
  transform: ${(p) => (p.$inView ? "translateY(0)" : "translateY(100px)")};
`

const StyledContent = styled.div`
  padding-top: 0;
  min-width: 100%;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 20px;
  }
`

const TwoRedCirclesContainer = styled.div<{ $about: boolean; $rtl: boolean }>`
  display: flex;
  align-items: center;
  padding-left: ${(p) => (p.$rtl ? "12px" : "0")};
  padding-right: ${(p) => (p.$rtl ? "0" : "12px")};
  padding-top: ${(p) => (p.$about ? "5px" : "15px")};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 12px;
  }
`

const StyledTitle = styled(Typography).attrs({ variant: "h2" })<{
  $about: boolean
  $rtl: boolean
}>`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 17px;
  padding-right: ${(p) => (p.$rtl ? "10px" : "0")};
  padding-left: ${(p) => (p.$rtl ? "0" : "10px")};
  color: ${(p) => p.theme.colors.blackDark};
  ${fontCondensed};
  font-size: ${(p) => (p.$about ? "44px" : "70px")};
  line-height: ${(p) => (p.$about ? "40px" : "84px")};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-right: 0;
    margin-bottom: 0;
    font-size: 44px;
    line-height: 40px;
  }
`

const StyledBorderContainer = styled.div<{ $about: boolean }>`
  position: absolute;
  left: 0;
  right: -2000px;
  ${(props) => props.$about && `top:0`};
`

const StylesBorder = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${colors.activitiesBorder};
`
type props = {
  blogInfo: PageField
  articles: BlogMain[]
  isAboutPage: boolean
}
export const ArticlesBlock = ({ blogInfo, articles, isAboutPage }: props) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    rootMargin: "300px 0px 0px 0px",
  })
  // const t = useTranslations("homepage")
  const [activeArticle, setActiveArticle] = useState(
    articles?.length ? articles[0] : null
  )
  const locale = useLocale()

  //Render
  return (
    <StyledContainer id={blogInfo?.page?.title === "מגזין אמות" && "magazine"}>
      <StyledStickyContainer
        desktopImageHeight={605}
        noPadding
        insideContainer={!isAboutPage}
        customImage={() =>
          activeArticle?.logo?.media?.publicUrl && (
            <QMediaComponent
              imageUrl={activeArticle?.logo?.media?.publicUrl}
              altText={activeArticle?.logo?.media?.altText}
            />
          )
        }
      >
        <StyledInnerContainer
          ref={inViewRef}
          $inView={inView}
          $about={isAboutPage}
          $rtl={locale === "he"}
        >
          <StyledContent>
            <Desktop>
              {isAboutPage && (
                <StyledBorderContainer $about={isAboutPage}>
                  <StylesBorder />
                </StyledBorderContainer>
              )}
            </Desktop>
            <StyledTitle $about={isAboutPage} $rtl={locale === "he"}>
              <TwoRedCirclesContainer
                $about={isAboutPage}
                $rtl={locale === "he"}
              >
                <TwoRedCircles />
              </TwoRedCirclesContainer>
              {blogInfo?.page?.title}
            </StyledTitle>
            <Desktop>
              {!isAboutPage && (
                <StyledBorderContainer $about={isAboutPage}>
                  <StylesBorder />
                </StyledBorderContainer>
              )}
            </Desktop>
            <NewsSlider articles={articles} onChange={setActiveArticle} />
          </StyledContent>
        </StyledInnerContainer>
      </StyledStickyContainer>
    </StyledContainer>
  )
}
