import { Box } from "@material-ui/core"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useTranslations } from "next-intl"
import styled from "styled-components"
import colors from "theme/colors"
import { FormAssetsSearch } from "./FormAssetsSearch"

const StyledBox = styled(Box)`
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 5px;
  margin: 15px 0 25px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 300px;
    margin: 0;
  }

  .MuiTextField-root {
    height: auto;
    min-width: 0;
  }
`
type Props = {
  onEnter?: () => void
}

const FormAssetsAutocomplete = ({ onEnter }: Props) => {
  const t = useTranslations("searchPopup")
  const th = useTranslations("homepage")
  const { setSelectedAssetsResult, activeCategoryType } =
    useAssetSearchContext()

  return (
    <StyledBox>
      <FormAssetsSearch
        autoComplete="search-assets"
        onSearch={setSelectedAssetsResult}
        name="search-assets"
        label={t("inputPlaceholder")}
        onEnter={onEnter}
        extraLabel={`${th("lookingFor")}${t(activeCategoryType)}?`}
      />
    </StyledBox>
  )
}

export default FormAssetsAutocomplete
