import { Button, Typography } from "@material-ui/core"
import { Category } from "api"
import { AssetType, getAssetLobbyPath } from "common/AssetType"
import NextLink from "common/NextLink"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useLocale, useTranslations } from "next-intl"
import Image from "next/image"
import styled from "styled-components"
import { fontCondensed } from "theme/GlobalStyle"
import colors from "theme/colors"
import FormikAssetsAutocomplete from "../../popups/FormAssetsAutocomplete"
const StyledActiviesContent = styled.div`
  position: relative;
`

const StyledActiviesInner = styled.div<{
  $rtl: boolean
}>`
  padding-right: ${(p) => (p.$rtl ? "88px" : "52px")};
  padding-left: ${(p) => (p.$rtl ? "52px" : "88px")};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 32px;
  }
`

const StyledActiviesContentTitle = styled.div`
  margin-bottom: 10px;

  ${fontCondensed};
  font-size: 44px;
  line-height: 0.91;
  font-weight: 400;
  color: ${(p) => p.theme.colors.blackDark};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 36px;
  }
`

const StyledActiviesContentDescription = styled.div`
  color: ${(p) => p.theme.colors.black};
  font-size: 18px;
  line-height: 1.44;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 75px;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`

const StyledLearnMoreButton = styled(Button)`
  padding: 0;
  margin-bottom: 47px;
  justify-content: flex-start;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 31px;
  }
`

const StyledLearnMoreText = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-transform: none;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

const StyleArrowLeftImageContainer = styled.div<{ $rtl: boolean }>`
  height: 31px;
  margin: 0 10px 4px 10px;
  transform: rotateY(${(p) => (p.$rtl ? "0" : "180deg")});

  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 18px;
  }
`

const StyledBorderContainer = styled.div`
  position: absolute;
  left: -2000px;
  right: 0;
`

const StyledBorder = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${colors.activitiesBorder};
`

const StyledFindOfficeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 47px;
  align-items: flex-end;
  .MuiContainer-root {
    padding-right: 0;
  }

  img {
    margin-right: 0;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: block;
    margin-bottom: 23px;
  }
  .MuiAutocomplete-root {
    display: flex;
    align-items: center;
    border-bottom: solid 1px ${colors.gray};
    padding-bottom: 5px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin-bottom: 25px;
    }
  }
`

const StyledSearchButton = styled(Button)`
  padding: 8px 67px;

  border-radius: 5px;
  border: solid 1.5px ${colors.black};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 3px 35px;
    width: 165px;
  }
`

const StyledSearchButtonText = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  text-transform: none;
`

const StyledFindOfficeTitle = styled(Typography).attrs({ component: "h4" })`
  padding-top: 51px;
  font-size: 18px;
  line-height: 1.44;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 24px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`

type Props = {
  category: Category
  categoryType: AssetType
}

export const ActivityContent = ({ category, categoryType }: Props) => {
  const { activeCategoryType, setOpenMapOrList } = useAssetSearchContext()
  const locale = useLocale()
  const closeMenuAndOpen = () => {
    setOpenMapOrList("list")
  }

  const t = useTranslations("homepage")

  const getOfficeTitle = () => {
    // @ts-ignore
    return `${t("lookingFor")}${t(activeCategoryType)}?`
  }

  return (
    <StyledActiviesContent>
      <StyledActiviesInner $rtl={locale === "he"}>
        <StyledActiviesContentTitle role="heading" aria-level={3}>
          {category?.title?.value}
        </StyledActiviesContentTitle>
        <StyledActiviesContentDescription>
          {category?.shortDescription?.value}
        </StyledActiviesContentDescription>

        <NextLink passHref href={getAssetLobbyPath(categoryType)}>
          <StyledLearnMoreButton role="link">
            <StyledLearnMoreText>{t("moreInfo")}</StyledLearnMoreText>
            <Typography variant="srOnly">{category?.title?.value}</Typography>
            <StyleArrowLeftImageContainer $rtl={locale === "he"}>
              <Image src="/arrowLeftIcon.svg" alt="" width={7} height={14} />
            </StyleArrowLeftImageContainer>
          </StyledLearnMoreButton>
        </NextLink>
      </StyledActiviesInner>

      <StyledBorderContainer>
        <StyledBorder />
      </StyledBorderContainer>

      <StyledActiviesInner $rtl={locale === "he"}>
        <StyledFindOfficeTitle>{getOfficeTitle()}</StyledFindOfficeTitle>

        <StyledFindOfficeInputContainer>
          <FormikAssetsAutocomplete onEnter={closeMenuAndOpen} />
          <StyledSearchButton onClick={closeMenuAndOpen}>
            <StyledSearchButtonText>{t("searchAsset")}</StyledSearchButtonText>
          </StyledSearchButton>
        </StyledFindOfficeInputContainer>
      </StyledActiviesInner>

      <StyledBorderContainer>
        <StyledBorder />
      </StyledBorderContainer>
    </StyledActiviesContent>
  )
}
