import React from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';

const StyledBigCircle = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 22px;
  border: 3px solid ${colors.red};
`;

const StyledSmallCircle = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 10px;
  background-color: ${colors.red};
`;

export const TwoRedCircles: React.FC = () => {
  return (
    <StyledBigCircle>
      <StyledSmallCircle />
    </StyledBigCircle>
  );
};
