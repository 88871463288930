import { Typography } from "@material-ui/core"
import { AmotInNumbers } from "api"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import colors from "theme/colors"
import { fontCondensed } from "theme/GlobalStyle"
import { StyledLineEllipsis } from "../../../src/styles"

const StylesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 44px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 44px;
  }
`
type Props = {
  numbers: AmotInNumbers[]
}

export const ThirdBlockGrid = ({ numbers }: Props) => {
  return (
    <StylesGrid>
      {numbers?.map((number, index) => (
        <Item
          key={index}
          value={(number.mainNumber?.value || 0).toLocaleString("he-IL", {
            maximumFractionDigits: 2,
          })}
          text={number.shortDescription?.value}
        />
      ))}
    </StylesGrid>
  )
}

const StylesGridItem = styled.div`
  height: 100%;

  margin-bottom: 40px;

  div {
    justify-self: center;
  }
`

const StyledGridItemRedText = styled(Typography)`
  margin-bottom: 10px;

  font-size: 80px;
  font-weight: 100;
  line-height: 80px;
  /* i think er are missing this font weight:  FbJabutinski-Con in 300*/
  ${fontCondensed};
  color: ${colors.red};
  font-weight: 300;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 60px;
    line-height: 60px;
  }
`

const StyledGridItemBorder = styled.div`
  margin-bottom: 14px;

  width: 100%;
  height: 2px;

  background-color: ${colors.activitiesBorder};
`

const StyledGridItemDescriptionContainer = styled.div`
  width: 100%;
`

const StyledGridItemDescriptionText = styled.div`
  font-size: 16px;
  line-height: 1.38;
  color: ${(p) => p.theme.colors.black};
  font-weight: normal;
  ${StyledLineEllipsis}
`

type ItemProps = {
  value: string
  text: string
}

const Item: React.FC<ItemProps> = ({ value, text }) => {
  const numberValue = parseInt(value.replace(",", ""))
  const { ref, inView } = useInView({ rootMargin: "-30px", triggerOnce: true })
  const valueRef = useRef(0)
  const accumulator = numberValue / 50
  const [state, setState] = useState<number | string>(0)
  const [finished, setFinished] = useState(false)
  const updateCounterState = useCallback(() => {
    if (valueRef.current < numberValue) {
      const result = Math.ceil(valueRef.current + accumulator)
      if (result > numberValue) return setState(value)
      setState(result)
      valueRef.current = result
    } else {
      setFinished(true)
    }
    setTimeout(updateCounterState, 50)
  }, [])
  useEffect(() => {
    if (inView) {
      updateCounterState()
    }
  }, [inView])
  return (
    <StylesGridItem ref={ref}>
      <StyledGridItemRedText>{finished ? value : state}</StyledGridItemRedText>
      <StyledGridItemBorder />

      <StyledGridItemDescriptionContainer>
        <StyledGridItemDescriptionText numberOfLines={3}>
          {text}
        </StyledGridItemDescriptionText>
      </StyledGridItemDescriptionContainer>
    </StylesGridItem>
  )
}
