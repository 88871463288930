import { Box, Container } from "@material-ui/core"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useLocale } from "next-intl"
import { useEffect } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import LocalBuinessSEO from "./LocalBusinessSEO"
import { ArticlesBlock } from "./blocks/ArticlesBlock"
import { AssetsBlock } from "./blocks/AssetsBlock"
import { FirstBlock } from "./blocks/FirstBlock"
import { NumbersBlock } from "./blocks/NumbersBlock"
import { HomeProps } from "./components/HomeProps"

const StyledContainer = styled(Box)`
  position: relative;
  padding: 0;
  overflow: hidden;
`

const StyledVerticalLine = styled.div`
  position: absolute;
  top: 0;
  right: 60px;
  bottom: 0;

  width: 1px;

  z-index: 10;
  background-color: ${colors.activitiesBorder};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    right: 50px;
    height: 1500px;
  }
`

const Homepage = ({
  data: homepageData,
  fallbackTitle,
  blogData,
  objectForSwiper,
}: HomeProps) => {
  const locale = useLocale()

  const { popUpIsOpen, setHomePageDataCategories } = useAssetSearchContext()
  useEffect(() => {
    setHomePageDataCategories(homepageData.categories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homepageData.categories])

  return (
    <Container disableGutters maxWidth="xl" style={{ position: "relative" }}>
      <LocalBuinessSEO />
      <StyledContainer>
        {popUpIsOpen && <StyledVerticalLine />}
        <FirstBlock data={homepageData} fallbackTitle={fallbackTitle} />
        <AssetsBlock data={homepageData} objectForSwiper={objectForSwiper} />
        <NumbersBlock data={homepageData} />
        {locale === "he" ? (
          <ArticlesBlock
            blogInfo={homepageData.main?.blogLink}
            articles={blogData?.articles}
            isAboutPage={false}
          />
        ) : null}
      </StyledContainer>
    </Container>
  )
}

export default Homepage
