import { Box, Typography } from "@material-ui/core"
import { Category } from "api"
import { UseSiteId } from "api/SiteId"
import { AssetListWithoutSupermarketType, AssetType } from "common/AssetType"
import useLocationLocale from "common/hooks/useLocationLocale"
import { useIsDesktop } from "common/MediaQueries"
import NextImage from "common/NextImage"
import { useLocale } from "next-intl"
import { useEffect, useRef, useState } from "react"
import Slider, { Settings } from "react-slick-pnth"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import styled from "styled-components"
import colors from "theme/colors"
import { ObjectForSwiperType } from "./HomeProps"

const StyledSliderContainer = styled(Slider)<{ $rtl: boolean }>`
  height: 100%;

  z-index: 1001;
  background-color: ${colors.white};

  --prev-arrow: url(${(p) =>
    p.$rtl ? "/arrowRightIconWhite.svg" : "/arrowLeftIconWhite.svg"});
  --next-arrow: url(${(p) =>
    p.$rtl ? "/arrowLeftIconWhite.svg" : "/arrowRightIconWhite.svg"});
  div {
    height: 100%;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 100vw;
      height: 100vw;
    }
  }

  svg {
    fill: #fff !important;
    stroke-width: 2px;
  }

  & .slick {
    &-dots {
      bottom: 36px;
      left: ${(p) => (p.$rtl ? "84px" : "auto")};
      right: ${(p) => (p.$rtl ? "auto" : "109px")};
      width: inherit;
      //direction: ${(p) => (p.$rtl ? "rtl" : "ltr")};

      & li {
        margin: 0;
      }

      & li.slick-active button:before {
        color: ${colors.red};
      }

      & li button:before {
        color: ${colors.darkGray};
        opacity: 1;
      }

      ${({ theme }) => theme.breakpoints.down("sm")} {
        left: ${(p) => (p.$rtl ? "32px" : "auto")};
        right: ${(p) => (p.$rtl ? "auto" : "56px")};
      }
    }

    &-arrow {
      top: auto;
      bottom: 45px;

      width: 30px;
      height: 30px;

      z-index: 1;

      &:not(.slick-disabled) {
        cursor: pointer;
      }

      & > i {
        font-size: 24px;
        color: inherit;
      }
    }

    &-disabled {
      opacity: 0.4;
    }

    &-prev {
      right: ${(p) => (p.$rtl ? "auto" : "138px")};
      left: ${(p) => (p.$rtl ? "138px" : "auto")};

      &:before {
        content: url(${(p) =>
          p.$rtl ? "/arrowRightIconWhite.svg" : "/arrowLeftIconWhite.svg"});
      }
    }

    &-next {
      left: ${(p) => (p.$rtl ? "80px" : "auto")};
      right: ${(p) => (p.$rtl ? "auto" : "80px")};

      &:before {
        content: url(${(p) =>
          p.$rtl
            ? "/arrowLeftIconWhite.svg"
            : "/arrowRightIconWhite.svg"}) !important;
      }
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      &-prev {
        left: ${(p) => (p.$rtl ? "85px" : "auto")};
        right: ${(p) => (p.$rtl ? "auto" : "85px")};
      }

      &-next {
        left: ${(p) => (p.$rtl ? "28px" : "auto")};
        right: ${(p) => (p.$rtl ? "auto" : "28px")};
      }
    }
  }
`

const StyledImageBox = styled(Box)`
  position: relative;
`

const StyledBuildingContainer = styled.span<{ $rtl: boolean }>`
  position: absolute;
  right: ${(p) => (!p.$rtl ? "auto" : "34px")};
  left: ${(p) => (!p.$rtl ? "34px" : "auto")};
  bottom: 40px;
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    right: 30px;
    bottom: 36px;
    .MuiTypography-root {
      line-height: 22px;
    }
  }
`

const StyledBuildingText = styled(Typography)`
  font-size: 16px;
  color: ${colors.white};
  line-height: 22px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 14px;
  }
`
const StyledGradientOverlay = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0, 0.8));
  width: 100%;
  height: 60%;
`
const sliderSettings: Settings = {
  dots: true,
  speed: 400,
  slidesToShow: 1,
  arrows: true,
  infinite: false,
  focusOnSelect: false,
  accessibility: true,
  // lazyLoad: "progressive"
}
type Props = {
  type: AssetType
  objectForSwiper: ObjectForSwiperType
  ActiveCategory: Category
}

export const GallerySlider = ({
  type,
  objectForSwiper,
  ActiveCategory,
}: Props) => {
  const [items, setItems] = useState<AssetListWithoutSupermarketType[]>([])
  const siteId = UseSiteId()
  const locale = useLocale()
  const mapLocation = useLocationLocale()
  const isDesktop = useIsDesktop()
  const ref = useRef<Slider>()
  useEffect(() => {
    if (ref.current) {
      ref.current.slickGoTo(0)
    }
  }, [ActiveCategory])
  useEffect(() => {
    setItems(objectForSwiper[type] || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, type])

  useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        const arrows = document?.querySelectorAll(
          "button.slick-arrow, .slick-dots button"
        )
        // for all arrows add role presentation and aria-hidden true
        arrows.forEach((arrow) => {
          arrow.setAttribute("role", "presentation")
          arrow.setAttribute("aria-hidden", "true")
        })
      })
    }
  }, [])

  return (
    <StyledSliderContainer
      ref={ref}
      {...sliderSettings}
      $rtl={locale === "he"}
      rtl={locale === "he"}
    >
      {items &&
        items?.map((asset, i) => {
          const address = `${
            locale === "en" ? asset?.location?.building?.value || "" : ""
          } ${asset?.location?.street?.value || ""} ${
            locale === "he" ? asset?.location?.building?.value || "" : ""
          }, ${
            mapLocation(asset?.location?.city?.value as unknown as string) || ""
          }`
          const image = isDesktop
            ? asset?.mainExtended?.logoDesktop
            : asset?.mainExtended?.logoMobile

          return (
            <StyledImageBox key={i}>
              <NextImage
                image={{
                  imageUrl: image?.media?.publicUrl || "/",
                  alt: image?.media?.altText,
                }}
              />
              <StyledGradientOverlay />
              <StyledBuildingContainer $rtl={locale === "he"}>
                <StyledBuildingText>
                  {asset?.main?.name?.value}
                </StyledBuildingText>
                {type !== "supermarket" && (
                  <StyledBuildingText>{address}</StyledBuildingText>
                )}
              </StyledBuildingContainer>
            </StyledImageBox>
          )
        })}
    </StyledSliderContainer>
  )
}
