import { Container, Typography } from "@material-ui/core"
import { useLocale, useTranslations } from "next-intl"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { fontCondensed } from "theme/GlobalStyle"
import { Graphic } from "../components/Graphic"
import { HomeProps } from "../components/HomeProps"
import { ThirdBlockGrid } from "../components/ThirdBlockGrid"
import { TwoRedCircles } from "../components/TwoRedCircles"

const StyledContainer = styled(Container)<{ $rtl: boolean; $inView: boolean }>`
  margin-bottom: 120px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0 32px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    margin-right: auto;
    margin-left: 0;
    padding: 0 100px;
    margin-left: ${(p) => (p.$rtl ? "0" : "auto")};
    margin-right: ${(p) => (p.$rtl ? "auto" : "0")};
  }
  transition: all 0.3s ease-in-out;
  opacity: ${(p) => (p.$inView ? 1 : 0)};
  transform: ${(p) => (p.$inView ? "translateY(0)" : "translateY(100px)")};
`

const StyledTitlesContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-column-gap: 20px;
  margin-bottom: 66px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: block;
    margin-bottom: 29px;
  }
`

const StyledTitleLeft = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.black};
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`
const StyledTitleRight = styled(Typography).attrs({ variant: "h2" })<{
  $rtl: boolean
}>`
  display: flex;
  justify-content: flex-start;
  padding-right: ${(p) => (p.$rtl ? "10px" : "0")};
  padding-left: ${(p) => (p.$rtl ? "0" : "10px")};
  ${fontCondensed};
  font-size: 70px;
  color: ${(p) => p.theme.colors.blackDark};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 20px;
    padding-right: 0px;
    font-size: 44px;
  }
`

const StyledTwoCirclesContainer = styled.div<{ $rtl: boolean }>`
  padding-left: ${(p) => (p.$rtl ? "15px" : "0")};
  padding-right: ${(p) => (p.$rtl ? "0" : "15px")};
  padding-top: 15px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-left: 10px;
    padding-top: 0px;
  }
`

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 70px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    display: block;
  }
`

const StyledSubTitle = styled(Typography).attrs({ component: "h3" })`
  margin-bottom: 40px;
  ${fontCondensed};
  font-size: 44px;
  line-height: 0.91;
  color: ${(p) => p.theme.colors.blackDark};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 20px;

    font-size: 34px;
    line-height: 1.18;
  }
`

const StylesRightItem = styled.div<{ $rtl: boolean }>`
  padding-right: ${(p) => (p.$rtl ? "50px" : "0")};
  padding-left: ${(p) => (p.$rtl ? "0" : "50px")};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-right: 0px;
  }
`

const StylesLeftItem = styled.div``

export const NumbersBlock = ({ data }: HomeProps) => {
  const t = useTranslations("homepage")
  const locale = useLocale()
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    rootMargin: "300px 0px 0px 0px",
  })

  return (
    <StyledContainer ref={inViewRef} $inView={inView} $rtl={locale === "he"}>
      <StyledTitlesContainer>
        <StyledTitleRight $rtl={locale === "he"}>
          <StyledTwoCirclesContainer $rtl={locale === "he"}>
            <TwoRedCircles />
          </StyledTwoCirclesContainer>

          {data?.main?.amotInNumbersTitle?.value || t("amotInNumbers")}
        </StyledTitleRight>
        <StyledTitleLeft>
          {data?.main?.amotInNumbersShortDescription?.value}
        </StyledTitleLeft>
      </StyledTitlesContainer>

      <StyledContent>
        <StylesRightItem $rtl={locale === "he"}>
          <StyledSubTitle>
            {data?.main?.overviewTitle?.value || t("overview")}
          </StyledSubTitle>
          <ThirdBlockGrid numbers={data?.numbers} />
        </StylesRightItem>

        <StylesLeftItem>
          <StyledSubTitle>
            {data?.main?.amotStockTitle?.value || t("amotStock")}
          </StyledSubTitle>

          <Graphic />
        </StylesLeftItem>
      </StyledContent>
    </StyledContainer>
  )
}
