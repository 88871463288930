import { LocalBusinessJsonLd } from "next-seo"

const LocalBusinessSEO = () => (
  <LocalBusinessJsonLd
    type="LocalBusiness"
    id="https://amot.co.il"
    name="Amot Investments Ltd"
    description="Amot Investments Ltd. is a leading Israeli real estate company. The company is a yielding real estate investments branch of Alony-Hetz Properties & Investments Ltd., Alony-Hetz owns about 54% of the Company and is a long-term committed shareholder."
    telephone="*2668"
    address={{
      streetAddress: "Amot Atrium Tower - 2 Jabotinsky Road",
      addressLocality: "Ramat Gan",
      addressRegion: "Ramat Gan",
      postalCode: "5250501",
      addressCountry: "IL",
    }}
  />
)

export default LocalBusinessSEO
