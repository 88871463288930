import {
  getBlog,
  getHome,
  getIndustryListCount,
  getInitiationListCount,
  getMallListCount,
  getOfficeListCount,
} from "api"
import { getSiteIdByLocale } from "api/SiteId"
import { AssetListWithoutSupermarketType } from "common/AssetType"
import { HomeProps } from "components/homepage/components/HomeProps"
import Homepage from "components/homepage/Homepage"
import { GetStaticProps } from "next"
import { NextSeo } from "next-seo"

const reducePageDataToGalleryData = ({
  main,
  mainExtended,
  location,
}: AssetListWithoutSupermarketType) =>
  ({
    main,
    mainExtended,
    location,
  } as AssetListWithoutSupermarketType)

const Home = ({ data, blogData, objectForSwiper }: HomeProps) => {
  return (
    <>
      <NextSeo
        title={data?.metaTitle}
        description={data?.metaDescription}
        additionalMetaTags={[{ name: "keywords", content: data.metaKeywords }]}
        openGraph={{
          title: data?.ogTitle || data?.metaTitle,
          description: data?.ogDescription || data?.metaDescription,
          images: [{ url: data?.ogImage?.media?.publicUrl || "" }],
        }}
      />
      <Homepage
        data={data}
        blogData={blogData}
        objectForSwiper={objectForSwiper}
        fallbackTitle={data?.metaTitle}
      />
    </>
  )
}

export default Home

export const getStaticProps: GetStaticProps<HomeProps> = async ({ locale }) => {
  const siteId = getSiteIdByLocale(locale)
  const [
    { data },
    { data: blogData },
    { data: OfficeListCount },
    { data: MallListCount },
    { data: IndustryListCount },
    { data: InitiationListCount },
  ] = await Promise.all([
    getHome(siteId),
    getBlog(siteId),
    getOfficeListCount("4", siteId),
    getMallListCount("4", siteId),
    getIndustryListCount("4", siteId),
    getInitiationListCount("4", siteId),
  ])

  const objectForSwiper = {
    offices: OfficeListCount.map(reducePageDataToGalleryData),
    "shopping-mall": MallListCount.map(reducePageDataToGalleryData),
    industry: IndustryListCount
      ? IndustryListCount.map(reducePageDataToGalleryData)
      : [],
    "in-progress": InitiationListCount.map(reducePageDataToGalleryData),
    // supermarket: SupermarketListCount,
  }
  return {
    props: {
      messages: (await import(`../../messages/${locale}.json`)).default,
      data,
      blogData,
      objectForSwiper,
    },
    revalidate: 60,
  }
}
