import { Button, Link, Typography } from "@material-ui/core"
import { BlogMain } from "api"
import { useLocale, useTranslations } from "next-intl"
import { useEffect } from "react"
import Slider, { Settings } from "react-slick-pnth"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import styled from "styled-components"
import colors from "theme/colors"
import { fontCondensed } from "theme/GlobalStyle"

const StyledSliderContainer = styled(Slider)<{ $rtl: boolean }>`
  padding-inline-start: 50px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-inline-start: 0;
  }

  & .slick {
    &-dots {
      bottom: 177px;
      right: ${(p) => (!p.$rtl ? "auto" : "50px")};
      left: ${(p) => (p.$rtl ? "auto" : "50px")};
      width: inherit;

      & li {
        margin: 0;
      }

      & li.slick-active button:before {
        color: ${colors.red};
      }

      & li button:before {
        color: #8f9091;
      }

      ${({ theme }) => theme.breakpoints.down("sm")} {
        right: auto;
        bottom: 32px;
      }
    }

    &-arrow {
      top: auto;
      left: auto;
      right: auto;
      bottom: 160px;

      width: 30px;
      height: 30px;

      z-index: 1;

      & :not(.slick-disabled) {
        cursor: pointer;
      }

      & > i {
        font-size: 24px;
        color: inherit;
      }

      ${(p) => p.theme.breakpoints.up("md")} {
        margin-right: 40px;
      }
    }

    &-disabled {
      opacity: 0.4;
    }

    &-prev {
      right: ${(p) => (p.$rtl ? "155px" : "auto")};
      left: ${(p) => (!p.$rtl ? "155px" : "auto")};

      &:before {
        content: url(${(p) =>
          p.$rtl ? "/arrowRightIcon.svg" : "/arrowLeftIcon.svg"});
      }
    }

    &-next {
      right: ${(p) => (p.$rtl ? "195px" : "auto")};
      left: ${(p) => (!p.$rtl ? "195px" : "auto")};

      &:before {
        content: url(${(p) =>
          p.$rtl ? "/arrowLeftIcon.svg" : "/arrowRightIcon.svg"});
      }
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      &-prev {
        bottom: 16px;
      }

      &-next {
        bottom: 16px;
      }
    }
  }
`

const sliderSettings: Settings = {
  dots: true,
  speed: 400,
  slidesToShow: 1,
  arrows: true,
  infinite: false,
  focusOnSelect: false,
}

type Props = {
  articles: BlogMain[]
  onChange: (article: BlogMain) => void
}

export const NewsSlider = ({ articles, onChange }: Props) => {
  const locale = useLocale()

  useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        const arrows = document?.querySelectorAll(
          "button.slick-arrow, .slick-dots button"
        )
        // for all arrows add role presentation and aria-hidden true
        arrows.forEach((arrow) => {
          arrow.setAttribute("role", "presentation")
          arrow.setAttribute("aria-hidden", "true")
        })
      })
    }
  }, [])
  return (
    <StyledSliderContainer
      rtl={locale === "he"}
      $rtl={locale === "he"}
      {...sliderSettings}
      afterChange={(index) => {
        onChange(articles[index])
      }}
    >
      {articles?.map((article, index) => (
        <NewsItem key={index} article={article} />
      ))}
    </StyledSliderContainer>
  )
}

const StylesNewsContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-inline-end: 32px;
  }
`

const StyledDateText = styled.div`
  padding-top: 44px;
  margin-bottom: 15px;
  color: ${(p) => p.theme.colors.black};

  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 24px;
    margin-bottom: 11px;
  }
`
const TextWrapper = styled.div<{ $isEng: boolean }>`
  min-height: 250px;
  direction: ${(props) => (props.$isEng ? "ltr" : "rtl")};
`
const SubTitleText = styled.div<{ marginBottom: number; $isEng: boolean }>`
  margin-bottom: ${(props) => props.marginBottom}px;

  ${fontCondensed};
  font-size: 44px;
  font-weight: 300;
  line-height: 53px;
  color: ${(p) => p.theme.colors.black};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 36px;
  }
`

const StyledDescriptionText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${(p) => p.theme.colors.black};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyledSearchButtonContainer = styled.div<{ $hebrew: boolean }>`
  display: flex;
  /* justify-content: ${(p) => (p.$hebrew ? "flex-start" : "flex-end")}; */
  padding-top: 82px;
  padding-right: 5px;
  margin-bottom: 90px;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding-top: 30px;
    margin-bottom: 90px;
  }
`

const StyledSearchButton = styled(Button).attrs({
  component: Link,
})<{ target: string; rel: string }>`
  padding: 8px 67px;
  margin-left: 2px;
  border-radius: 5px;
  border: solid 1.5px ${colors.black};
  text-decoration: none !important;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 3px 35px;
    width: 190px;
  }
  a {
    text-decoration: none !important;
  }
`

const StyledSearchButtonText = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  text-transform: none;
  text-decoration: none !important;
`

const NewsItem = ({ article }: { article: BlogMain }) => {
  const t = useTranslations("homepage")
  const locale = useLocale()

  // variable that represent time created by dd.MM.yyyy
  const date = new Date(article?.articleDate?.value).toLocaleDateString()
  const buttonLink = article?.articlePdf?.media?.publicUrl.length
    ? article?.articlePdf?.media?.publicUrl
    : article?.articleUrl?.value
    ? article?.articleUrl?.value
    : article?.article?.page?.slug

  return (
    <StylesNewsContainer>
      <TextWrapper $isEng={locale === "en"}>
        <StyledDateText>
          {article?.articleSource?.value} {date}
        </StyledDateText>
        <SubTitleText marginBottom={15} $isEng={locale === "en"}>
          {article?.name?.value}
        </SubTitleText>
        <StyledDescriptionText>
          {article?.shortText?.value}
        </StyledDescriptionText>
      </TextWrapper>
      <StyledSearchButtonContainer $hebrew={locale === "he"}>
        <StyledSearchButton
          href={buttonLink}
          role="link"
          target={`${article?.article?.page?.slug ? `_self` : `_blank`}`}
          rel="nofollow"
        >
          <StyledSearchButtonText>
            {t("moreDetail")}
            <Typography variant="srOnly">{article?.name?.value} </Typography>
          </StyledSearchButtonText>
        </StyledSearchButton>
      </StyledSearchButtonContainer>
    </StylesNewsContainer>
  )
}
